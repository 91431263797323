
import Vue from 'vue';
import { mapGetters } from 'vuex';

import levelQuantitySelection from '@/components/shared/level-quantity-selection.vue';

import { extractLevelAmountByLocationDefaultCurrency, isLevelAvailable } from '@/helpers/levels';
import { SlectedLevels } from '@/store/modules/models/levels';
import { getPreselectObj } from '@/store/modules/levels';
import customMapState from '../../helpers/mapHelper';
import { IRootState } from '../../models/IRootState';
import { IDonationLevel } from '../../models/IDonationParams';

const VueComponent = Vue.extend({
  components: {
    levelQuantitySelection,
  },

  data() {
    return {
      intl: new Intl.NumberFormat('en-US'),
    }
  },

  computed: {
    ...customMapState({
      lang: (state: IRootState) => state.translation.locate,
      donationAmount: (state: IRootState) => state.donation.donationData.attributes.amount / 100,
      panelColor: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.style_config
        .preset_colors.primary || '#ca6d4f',
      campaign: (state: IRootState) => state.donation.campaign.data.attributes,
      currencyList: (state: IRootState) => state.donation.donationParams
        .data.attributes.available_currency_list,
      config: (state: IRootState) => state.donation.donationParams.data.attributes.config,
      defaultCurrencyByIp: (state: IRootState) => state.donation.donationParams.data.attributes.ip_location_default_currency,
    }),

    ...mapGetters({
      getLevelByID: 'getLevelByID',
      getSelectedLevel: 'getSelectedLevel',
      hasLevelsWithSameAmount: 'hasLevelsWithSameAmount',
      showRecurringOption: 'showRecurringOption',
      showInstallmentOption: 'showInstallmentOption',
    }),

    quantitySelectionModel: {
      get(): SlectedLevels {
        const state = this.$store.state as IRootState

        return state.levels.slectedLevels;
      },

      set(value: SlectedLevels): void {
        this.$store.commit('setSlectedLevels', value)
        this.updateForm()
      },
    },

    disableAmount(): boolean {
      return this.config.levels_config.disable_amount || false;
    },

    quantitySelectionActive(): boolean {
      return this.config.levels_config.quantity_selection || false;
    },

    quantitySelectionTotal(): { total: number, currSign: string, currCode: string } {
      return Object.keys(this.quantitySelectionModel).reduce(
        (res, data) => {
          if (data) {
            const level = this.getLevelByID[data]
            const { levelAmount, levelCurrencySign, levelCurrencyCode } = extractLevelAmountByLocationDefaultCurrency(level, this.defaultCurrencyByIp)

            res.total += levelAmount * (this.quantitySelectionModel[data]?.count || 1);
            res.currSign = levelCurrencySign || this.currencySign;
            res.currCode = levelCurrencyCode;
          }

          return res;
        },
        {
          total: 0,
          currSign: '',
          currCode: '',
        },
      )
    },

    show(): boolean {
      return this.config.show_donation_levels_above_amount;
    },

    levels(): IDonationLevel[] {
      return this.config.donation_levels;
    },

    currencySign(): string {
      return this.campaign.currency_sign;
    },
  },

  created() {
    if (Object.keys(getPreselectObj()).length > 0) {
      this.$store.commit('setSlectedLevels', getPreselectObj())
      this.updateForm()
    }
  },

  methods: {
    isLevelAvailable,

    isActive(level: IDonationLevel): boolean {
      if (!this.hasLevelsWithSameAmount && this.donationAmount === level.amount) {
        return true
      }
      return Boolean(this.quantitySelectionModel[level.id])
    },

    getTitle(level: IDonationLevel): string {
      return level.meta?.title_loc?.[this.lang] || level.title;
    },

    getSubTitle(level: IDonationLevel): string {
      return level.meta?.subtitle_loc?.[this.lang] || level.subtitle;
    },

    getAmountText(level: IDonationLevel): string {
      const { currencySign } = this;

      const rp = !level?.meta?.installment ? level?.recurring_n : 0;

      const { levelAmount, levelCurrencySign } = extractLevelAmountByLocationDefaultCurrency(level, this.defaultCurrencyByIp)
      const currSign = levelCurrencySign || currencySign;

      return ` ${currSign}${this.intl.format(levelAmount)}${(rp > 1 ? this.$t('level_widget.month') : '')}`
    },

    onSelect(level: IDonationLevel) {
      const available = isLevelAvailable(level)

      if (!available) return;

      if (this.quantitySelectionActive) {
        this.quantitySelectionModel = {
          ...this.quantitySelectionModel,
          [level.id]: {
            count: (this.quantitySelectionModel[level.id]?.count || 0) + 1,
          },
        }

        return;
      }

      if (!this.quantitySelectionModel[level.id]) {
        this.quantitySelectionModel = { [level.id]: { count: 1 } }
      } else if (!getPreselectObj()?.[level?.id]) {
        this.quantitySelectionModel = {}
      }
    },

    updateForm() {
      const lvl: IDonationLevel = this.getSelectedLevel
      const { total: amt, currCode } = this.quantitySelectionTotal;

      if (this.currencyList.length && currCode) {
        const currency = this.currencyList.find(
          el => el.code.toLowerCase() === currCode,
        );
        if (currency) {
          this.$store.commit('setDonationCurrency', { ...currency });
        }
      }

      this.$store.commit('setOriginAmount', amt);

      // if there is a level selected from campaign page, set readonly
      if (getPreselectObj()?.[lvl?.id]) {
        this.$store.commit('setReadonly', this.disableAmount);
      } else if (Object.keys(this.quantitySelectionModel).length > 0) {
        // else if there is user selection, set readonly
        this.$store.commit('setReadonly', this.disableAmount);
      } else {
        this.$store.commit('setReadonly', false);
      }

      if (lvl && !this.quantitySelectionActive) {
        const rp = lvl.recurring_n || 0;
        const ip = lvl.recurring_n || 1;

        if (!lvl?.meta?.installment && this.showRecurringOption && rp > 1) {
          this.$store.commit('setRecurringPeriod', rp);
        }

        if (lvl.meta?.installment && this.showInstallmentOption && ip > 1) {
          this.$store.commit('setInstallmentStatus', Boolean(ip));
          this.$store.commit('setInstallmentPeriod', Number(ip));
        }
      }
    },
  },
});
export default class Levels extends VueComponent {}
