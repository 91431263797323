
import { Component, Vue, Watch } from 'vue-property-decorator';
import customMapState from './helpers/mapHelper';
import { gtag } from './helpers/googleTagManager'
import { get, getCampaignId } from './helpers/getUrlParameters';
import { getDataForAnalytics } from './helpers/analytics';
import { IRootState } from './models/IRootState';
import Dialog from './components/Dialog.vue';
import DonateExtended from './components/DonateExtended.vue';
import appErrorBlock from './components/shared/error-block/error-block.vue';
import appOverlay from './components/Overlay.vue';
import DonorRescue from './components/DonorRescue.vue';
import { removeBeforeUnloadListener } from './before-unload';

const VueComponent = Vue.extend({
  components: {
    appDialog: Dialog,
    appDonateExtended: DonateExtended,
    appErrorBlock,
    appOverlay,
    DonorRescue,
  },
  computed: customMapState({
    campaign: (state: IRootState) => state.donation.campaign,
    test: (state: IRootState) => state.donation.donationData.attributes.test,
    config: (state: IRootState) => state.donation.donationParams?.data?.attributes?.config,
    paymentConfig: (state: IRootState) => state.donation.paymentConfig,
    iframe: (state: IRootState) => state.donation.iframe,
    lang: (state: IRootState) => state.translation.locate,
    isDialogVisible: (state: IRootState) => state.donation.dialog.visible,
    fbPixelId(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes.config.tracking_fb_pixel_id || null;
      return null;
    },
    fbPixelEventName(state: IRootState) {
      const { data } = state.donation.donationParams;
      if (data) return data.attributes?.config?.tracking_fb_pixel_event_name || 'Purchase';
      return 'Purchase';
    },
    redirectConfig: (state: IRootState) => state.donation.donationParams.data.attributes.config?.redirect_config,
    donationDataAttrs: (state: IRootState) => state.donation.donationData.attributes,
    splitDonorName: (state: IRootState) => state.donation.donationParams.data.attributes.config.split_donor_billing_name,
  }),
})
@Component({})
export default class App extends VueComponent {
  private isModalVisible: boolean = false;

  private matcherPanel: boolean = true;

  private customView: string = get('custom_view') ? `custom-view-${get('custom_view')}` : '';

  created(): void {
    const cid = getCampaignId();
    if (cid.length) {
      document.title = `Donation Form - ${cid}`;
      this.$store.dispatch('getCampaign', { cid });
    } else {
      document.title = 'Donation Form - New Campaign'
      this.$store.dispatch('getNewCampaign');
    }

    // Listen to message from iframe element
    window.addEventListener('message', msg => {
      if (msg.data?.MeshulamActiveLoader_nauK1M54J) {
        this.$root.$emit('MeshulamActiveLoader_nauK1M54J', true);
      }

      if (msg.data === 'MeshulamCancelBitPayment_nauK1M54J') {
        this.$store.commit('setDialogVisibleStatus', false);
        this.$store.commit('setIsSubmitted', false);
      }

      if (msg.data && msg.data.type) {
        const sendToParent = (extra: Record<string, unknown> = {}) => {
          const inIframe = window.self !== window.parent;

          if (inIframe) {
            if (msg.data?.payload) {
              msg.data.payload = { ...msg.data.payload, ...extra };
            } else {
              msg.data.payload = extra;
            }
            window.parent.postMessage(msg.data, '*')
          }
        }

        const redirectInParent = (url: string) => {
          const inIframe = window.self !== window.parent;
          const installments = () => {
            if (this.donationDataAttrs.installment_period > 1) {
              return this.donationDataAttrs.installment_period;
            }
            if (this.donationDataAttrs.recurring_period > 1) {
              return this.donationDataAttrs.recurring_period;
            }
            return 0;
          }
          const variables: Record<string, unknown> = {
            phone: this.donationDataAttrs.phone,
            email: this.donationDataAttrs.email,
            name: this.splitDonorName
              ? `${this.donationDataAttrs.billing_first_name} ${this.donationDataAttrs.billing_last_name}`
              : this.donationDataAttrs.billing_name,
            amount: this.donationDataAttrs.amount,
            installments: installments(),
          }
          const replaceVarsInUrl = (url: string) => url.replace(/\{(\w+)\}/g, (_, key) => String(variables[key]));
          const replacedUrl = replaceVarsInUrl(url);

          if (inIframe) {
            window.parent.postMessage({
              type: 'redirect-in-parent',
              payload: {
                url: replacedUrl,
              },
            }, '*')
          } else {
            window.location.href = replacedUrl;
          }
        }

        if (msg.data.type === 'gateway-response-success') {
          const state = this.$store.state as IRootState;

          if (state.donation.stepFlag === 3) {
            return
          }

          // Show Success message
          if (!this.redirectConfig?.thank_you_step_redirect_url) {
            this.$store.commit('setDialogVisibleStatus', false);
            this.$store.commit('setStepFlag', 3);
          }

          const {
            cId,
            campaignTitle,
            donationId,
            realAmount,
            currency,
            teams,
            levels,
            gatewayName,
          } = getDataForAnalytics(state);

          sendToParent({ donationId });

          gtag('event', 'campaign_donated', {
            campaign_title: campaignTitle,
            real_amount: realAmount,
            event_category: 'Campaign',
            event_action: 'Donated',
            event_label: campaignTitle,
            value: realAmount,
          });

          gtag('event', 'purchase', {
            currency: currency.toUpperCase(),
            value: realAmount,
            transaction_id: String(donationId),
            items: [
              {
                index: 0,
                item_category: 'gateway',
                item_id: gatewayName,
                item_name: gatewayName,
                affiliation: `c_${cId} - ${campaignTitle}`,
                price: realAmount,
                quantity: 1,
              },
              ...teams.map((team, index) => ({
                index: index + 1,
                item_category: 'team',
                item_id: team.id,
                item_name: team.category,
                affiliation: `c_${cId} - ${campaignTitle}`,
                price: team.price,
                quantity: team.quantity,
              })),
              ...levels.map((level, index) => ({
                index: index + teams.length,
                item_category: 'level',
                item_id: level.id,
                item_name: level.category,
                item_variant: level.variant,
                affiliation: `c_${cId} - ${campaignTitle}`,
                price: level.price,
                quantity: level.quantity,
              })),
            ],
          });

          if (typeof (fbq) !== 'undefined') {
            fbq('track', this.fbPixelEventName, {
              value: realAmount,
              currency,
            });
          }

          this.$store.commit('setOverlay', false);

          if (this.redirectConfig?.thank_you_step_redirect_url) {
            removeBeforeUnloadListener();
            redirectInParent(this.redirectConfig.thank_you_step_redirect_url);
          }
        } else if (msg.data.type === 'gateway-response-error') {
          sendToParent()

          this.$store.commit('setDialogIframeSrc', '');
          this.$store.commit('setDialogVisibleStatus', false);

          this.$store.commit('setError', {
            title: 'Error!',
            translationKey: [msg.data?.key, msg.data.msg],
          });

          this.$store.commit('setIsSubmitted', false);
          this.$store.commit('setOverlay', false);
        }
      }
    });
  }

  mounted() {
    const observerOptions = {
      subtree: true, childList: true, attributes: true, characterData: true,
    }
    const observer = new MutationObserver(this.sendHeight);
    observer.observe(this.$refs.main as Node, observerOptions);

    this.sendHeight();
  }

  sendHeight() {
    window.parent.postMessage(
      {
        type: 'offset-height',
        payload: {
          height: (this.$refs.main as any)?.offsetHeight || 0,
        },
      },
      '*',
    );
  }

  @Watch('config')
  configLoaded(): void {
    if (this.config.prohibit_input_characters) {
      window.addEventListener('input', e => {
        const el = e.target;

        if (el instanceof HTMLInputElement && el.type === 'text') {
          const prohibitCharacters = new RegExp(`^(${this.config.prohibit_input_characters})*$`);
          const filterFn = prohibitCharacters.test.bind(prohibitCharacters);

          const newValue = el.value.split('').filter(filterFn).join('');

          if (newValue) {
            el.value = '';
            el.dispatchEvent(new CustomEvent('input'));
          }
        }
      });
    }
  }

  @Watch('campaign')
  campaignChanged(): void {
    this.$store.dispatch('getCampaignParams');
  }

  @Watch('fbPixelId')
  fbPixelTrack(value: null | string): void {
    if (value !== null) {
      fbq('init', this.fbPixelId);
      fbq('track', 'PageView');
    }
  }

  gatewayClass(): string {
    if (this.paymentConfig?.id) {
      return `${this.paymentConfig?.attributes?.name}-${this.paymentConfig.id}`
    }
    return ''
  }

  showModal(): void {
    this.isModalVisible = true;
  }

  closeModal(): void {
    this.isModalVisible = false;
  }

  get direction() {
    switch (this.lang) {
      case 'he':
      case 'ar':
        document.dir = 'rtl'
        return 'rtl'
      default:
        document.dir = 'ltr'
        return 'ltr'
    }
  }
}
