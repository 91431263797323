import { ActionTree, MutationTree } from 'vuex';
import { IRootState } from '@/models/IRootState';
import { NedarimPlusState } from './models/nedarimplus';
import replaceVarsInGatewayFields from '../../helpers/replaceVarsInGatewayFields'

const state: NedarimPlusState = {
  initiated: false,

  iframe: null,
  params: null,

  zeout: '',

  submitting: false,
}

const mutations: MutationTree<NedarimPlusState> = {
  setInitiated(state, data) {
    state.initiated = data
  },
  setParams(state, data) {
    state.params = data
  },
  setIfrme(state, data) {
    state.iframe = data
  },
  setZeout(state, data) {
    state.zeout = data
  },
  setSubmitting(state, data) {
    state.submitting = data
  },
}

const actions: ActionTree<NedarimPlusState, IRootState> = {
  NedarimInit({ state, commit, dispatch }, { parameters }) {
    commit('setParams', parameters)

    const ReadPostMessage = (event: any) => {
      switch (event?.data?.Name) {
        case 'Height':
          // Here you get the height of iframe | event.data.Value
          break;

        case 'TransactionResponse':
          if (event.data.Value.Status === 'Error') {
            commit('setError', {
              title: 'Nedarim Transaction Response Error!',
              text: event.data.Value.Message,
            });
            dispatch('trackClientError');
          } else {
            window.postMessage({
              type: 'gateway-response-success',
            }, '*');
          }
          commit('setSubmitting', false)
          break;

        default:
          break;
      }
    }

    if (!state.initiated) {
      window.addEventListener('message', ReadPostMessage, false);

      commit('setInitiated', true);
    }
  },

  NedarimSetIFrame({ commit }, iframe) {
    commit('setIfrme', iframe)
  },

  NedarimPost({ state }, Data) {
    state.iframe?.contentWindow?.postMessage(Data, '*');
  },

  NedarimPay({
    rootState, state, dispatch, commit,
  }) {
    commit('setSubmitting', true)

    const {
      MosadId,
      ApiValid,
      format_israeli_phone,
      NedarimGroupStatciValue,
      NedarimGroupDynamicValue,
      NedarimFieldsInfo,
      nedarim_callback_error_email,
    } = rootState.donation.paymentConfig.attributes.configuration;

    const {
      email,
      address_city,
      address_line1,
      phone,
      billing_first_name: bfn,
      billing_last_name: bln,
      receipt_name: rn,
      billing_name,
      campaign_id,
      tip_amount,
    } = rootState.donation.donationData.attributes;

    let newPhone = phone;
    if (format_israeli_phone === 'true' && phone.substring(0, 4) === '+972') {
      newPhone = phone.split('-').join('');
      newPhone = newPhone.split(' ').join('');
      newPhone = newPhone.replace('+972', '0');
    }

    let group = '';
    if (NedarimGroupStatciValue !== '') {
      group = NedarimGroupStatciValue;
    }

    if (NedarimGroupDynamicValue !== '') {
      switch (NedarimGroupDynamicValue) {
        case 'campaign_id':
          group = campaign_id;
          break;
        case 'donation_id':
          group = rootState.donation.donationId.toString();
          break;
        default:
          break;
      }
    }

    let nedarimFields;
    try {
      nedarimFields = JSON.parse(NedarimFieldsInfo);
    } catch {
      nedarimFields = {};
    }

    const Groupe = replaceVarsInGatewayFields(nedarimFields?.Groupe || '', rootState) || group;
    const Comment = replaceVarsInGatewayFields(nedarimFields?.Comment || '', rootState) || 'Charidy';

    const [fn, ...ln] = (rn || billing_name || `${bfn} ${bln}`).replace(/[`'"]+/g, '').split(' ');

    const data = {
      Name: 'FinishTransaction2',
      Value: {
        Mosad: MosadId,
        ApiValid,
        PaymentType: state.params?.payment_type,
        Currency: state.params?.currency_code,

        Zeout: state.zeout.trim() || '000000000',
        FirstName: fn,
        LastName: ln.join(' '),
        Street: address_line1,
        City: address_city,
        Phone: newPhone,
        Mail: email,

        Amount: (state.params?.charge_amount || 0) + (tip_amount / 100),
        Tashlumim: state.params?.tashlumim,

        Groupe,
        Comment,

        CallBack: state.params?.callback_url,

        Tokef: '',

        CallBackMailError: nedarim_callback_error_email,
      },
    }

    dispatch('NedarimPost', data);
  },
}

export default {
  state,
  mutations,
  actions,
}
