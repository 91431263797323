
import Vue from 'vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import customMapState from '@/helpers/mapHelper';
import urls from '@/constants/urls';
import { IRootState } from '../../models/IRootState';

const VueComponent = Vue.extend({
  components: {
    Multiselect,
  },

  data() {
    return {
      persPageFormData: {
        name: '',
        slug: '',
        goal: 0,
        parent_team_id: 0,
        description: '',
      },
      query: '',
      offset: 0,
      showMore: true,
      teams: [],
      selectedTeam: null,
      file: null,
      sending: false,
      isSuccess: false,
      error: null,
    };
  },

  computed: {
    ...customMapState({
      dId: (state: IRootState) => state.donation.donationId,
      cmpId: (state: IRootState) => state.donation.campaign.data.id,
      name: (state: IRootState) => state.donation.donationData.attributes.billing_name,
      email: (state: IRootState) => state.donation.donationData.attributes.email,
      cmpShortLink: (state: IRootState) => state.donation.campaign.data.attributes.short_link,
      cmpCurrency: (state: IRootState) => state.donation.campaign.data.attributes.currency,
      createPersonalPageConfig: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.create_personal_page_config,
      donateTeamSortByFeatured: (state: IRootState) => state.donation.donationParams
        .data.attributes.config.donate_team_sort_by_featured,
      campaignShortUrl: (state: IRootState) => state.donation.donationParams.data.attributes.campaign_short_url,
    }),

    filePreviewUrl(): string {
      if (this.file) {
        return URL.createObjectURL(this.file);
      }

      return '';
    },
  },

  watch: {
    name(value: string) {
      this.persPageFormData.name = `${value} Personal Page`;
      this.persPageFormData.slug = this.getSlug();
    },
    offset() {
      this.asyncFind(this.query);
    },
    selectedTeam(v) {
      this.persPageFormData.parent_team_id = Number(v.id);
    },
  },

  methods: {
    asyncFind(query: string) {
      const q = query || '';
      this.query = q;
      const params: any = {
        q,
        offset: 20 * this.offset,
        limit: 20,
        parent_only: 1,
      }
      if (this.donateTeamSortByFeatured) {
        params.sort = '-featured';
      }

      axios
        .get(
          `${urls.getTeams.replace(':campaignId', String(this.cmpId))}`,
          { params },
        )
        .then(response => {
          this.showMore = response.data.data.length > 0;
          if (this.offset === 0) {
            this.teams = response.data.data;
          } else {
            this.teams = this.teams.concat(response.data.data);
          }
        })
    },

    isAvailable(name: string): boolean {
      return this.createPersonalPageConfig && this.createPersonalPageConfig[name];
    },

    getSlug(): string {
      const { name } = this.persPageFormData;
      const dID = this.dId;

      const cleanName = name
        .replace(/[^A-Za-z0-9]/g, '')
        .replace('PersonalPage', '');

      if (cleanName.length > 0) {
        return cleanName;
      } if (dID > 0) {
        return String(dID);
      }
      return String(
        Math.floor(100000 + Math.random() * 900000),
      )
    },

    onSelectFile(e: any) {
      const [file] = e.target.files || [];

      if (file) {
        this.file = file;
      }
    },

    deleteImage() {
      this.file = null;
      (this.$refs as any).fileInput.value = null;
    },

    addTeam(teamData: any): void {
      this.error = null;
      this.sending = true;
      axios
        .post(
          urls.createTeam.replace(':id', String(this.cmpId)),
          teamData,
        )
        .then(() => {
          this.sending = false;
          this.isSuccess = true;
        })
        .catch(e => {
          this.error = e.response.data.error;
          this.sending = false;
        });
    },

    onSubmit(): void {
      this.sending = true;

      const teamData = {
        data: {
          attributes: {
            ...this.persPageFormData,
            image: '',
            donation_id: this.dId,
            leader_name: this.name,
            leader_email: this.email,
          },
        },
      };

      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);

        axios
          .post(urls.media.replace(':id', String(this.cmpId)), formData)
          .then(response => {
            teamData.data.attributes.image = response.data.src;
            this.addTeam(teamData);
          })
          .catch(e => {
            this.error = e.response.data.error;
            this.sending = false;
          });
      } else {
        this.addTeam(teamData);
      }
    },

    onFileInputClick(): void {
      (this.$refs as any).fileInput.click();
    },

    customLabel(v: any): string {
      return v.attributes.name;
    },
  },
});
export default class CreatePersonalPage extends VueComponent {}
